
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  background-color: rgb(38, 37, 37);
  height: 100vh;

  @media not all and (hover:hover) {
      height: var(--app-height);
  }
}

body {
  margin: 0;
  font-family: Bogart;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, 'Courier New',
    monospace;
}

.App {
  height: var(--app-height);
}
